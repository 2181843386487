<template>
  <div class="app-container">
    <!-- <el-dialog v-ismove :visible="true" title="拖动弹窗" width="800px">
      <div>阿萨德</div>
    </el-dialog> -->
    <el-form :model="searchForm" size="small" inline style="border-bottom: 1px solid  #dcdfe6">
      <el-form-item label="系统">
        <el-input v-model="searchForm.usName" clearable placeholder="输入名称过滤系统" @change="filterSystem"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="filterSystem(searchForm.usName)" :disabled="loading">查询</el-button>
        <el-button type="success" @click="addSystem" :disabled="loading">创建</el-button>
        <!-- 导入导出 -->
        <el-button type="primary" icon="el-icon-folder" v-permission="menuImport.importMenu" @click="importMenu('system')">导入</el-button>
        <el-button type="primary" icon="el-icon-download" v-permission="menuImport.exportMenu"  @click="exportAllMenu">导出</el-button>
      </el-form-item>
    </el-form>
    <!-- 内容部分 -->
    <el-container v-bind:style="{maxHeight: maxHeight + 'px'}">
      <el-aside width="220px" v-bind:style="{maxHeight: maxHeight + 'px'}" style="margin-top:10px">
        <el-row>
          <template v-for="item in systemList">
            <!-- <el-tooltip :key="item.usId" effect="dark" :content="item.usName" placement="top"> -->
              <p :key="item.usId" :class="{'active': item.usId === activeId}" class="systemPan" @click="changeSystem(item)">{{ item.usName }}</p>
            <!-- </el-tooltip> -->
          </template>
        </el-row>
      </el-aside>
      <el-main v-loading="loading" style="padding:10px">
        <el-row style="border-bottom: 1px solid  #dcdfe6">
          <el-col :span="21">
            <el-form ref="systemForm" :model="systemForm" :rules="rules" :disabled="systemType==='read'" label-width="90px" size="small" inline>
              <el-row>
                <el-col :lg="12" :md="24" :sm="24">
                  <el-form-item label="系统名称" prop="usName">
                    <el-input v-model="systemForm.usName" :style="{width: inputWidth}" placeholder="请输入系统名称，不能重复"></el-input>
                  </el-form-item>
                  <el-form-item label="系统地址" prop="usUrl">
                    <el-input v-model="systemForm.usUrl" :style="{width: inputWidth}" placeholder="请输入系统URL"></el-input>
                  </el-form-item>
                   <el-form-item label="负责人" prop="directorId">
                    <el-select v-model="systemForm.directorId" clearable size="small"
                      remote :remote-method="filterUser" filterable
                      :style="{width: inputWidth}" placeholder="请选择系统负责人">
                      <el-option v-for="item in userList" :key="item.userId" :label="item.name+'('+item.username+')'" :value="item.userId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="24" :sm="24">
                  <el-form-item label="系统备注" prop="description">
                    <el-input v-model="systemForm.description" :style="{width: inputWidth}" placeholder="请输入备注，类似别名"></el-input>
                  </el-form-item>
                  <el-form-item label="系统描述" prop="usDesc">
                    <el-input type="textarea" rows="4" v-model="systemForm.usDesc" :style="{width: inputWidth}" placeholder="请输入系统描述"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="3" style="text-align:right">
            <el-button v-show="systemType==='read'" type="success" size="small" @click="systemType='edit'">编辑</el-button>
            <el-button v-show="systemType==='read'" type="danger" size="small" @click="deleteSystem">删除</el-button>
            <el-button v-show="systemType==='edit'" type="primary" size="small" @click="saveEdit">保存</el-button>
            <el-button v-show="systemType==='add'" type="primary" size="small" @click="saveAdd">确定</el-button>
            <el-button v-show="systemType!=='read'" size="small" @click="cancelOpt">取消</el-button>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-button type="primary" size="mini" @click="addTopNode()">添加顶级菜单</el-button>
          <!-- 导入导出 -->
          <el-button type="primary" icon="el-icon-folder" v-permission="menuImport.importMenu" size="mini" @click="importMenu('item')">导入</el-button>
          <el-button type="primary" icon="el-icon-download" v-permission="menuImport.exportMenu" size="mini" @click="exportAllMenu('sys')">导出</el-button>
        </el-row>
        <div class="tip_txt">提示：单页面也需要先建一个菜单，再建一个页面(页面挂在菜单下面)</div>
        <!-- 树形菜单 -->
        <el-row :gutter="20" style="padding: 0px 20px;">
          <el-col :lg="8" :md="24" :sm="24" style="margin-bottom:10px; padding:10px 5px;">
            <el-tree
              :data="treeData"
              show-checkbox
              default-expand-all
              :expand-on-click-node="false"
              node-key="umId"
              ref="tree"
              :highlight-current="true"
              :props="defaultProps"
              :check-strictly="true"
              :render-content="treeRenderContent">
            </el-tree>
          </el-col>
          <el-col :lg="16" :md="24" :sm="24" style="padding: 10px 5px; position: relative">
            <template>
              <el-form ref="nodeForm" :model="nodeForm" :rules="nodeRules" label-width="100px" size="small">
                <el-row>
                  <el-col :lg="12" :md="24" :sm="24">
                    <el-form-item label="所属父菜单">
                      <el-input v-model="parentNode" disabled placeholder="所属父菜单"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :lg="12" :md="24" :sm="24">
                    <el-form-item label="中文标题" prop="umTitle">
                      <el-input v-model="nodeForm.umTitle" placeholder="请输入"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :lg="12" :md="24" :sm="24">
                    <el-form-item label="英文标题" prop="umEnTitle">
                      <el-input v-model="nodeForm.umEnTitle" placeholder="请输入"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :lg="12" :md="24" :sm="24">
                    <el-form-item label="排序号" prop="umSort">
                      <el-input v-model="nodeForm.umSort" placeholder="排序号影响菜单的排列顺序"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :lg="12" :md="24" :sm="24">
                    <el-form-item label="URL" prop="umUrl">
                      <el-input v-model="nodeForm.umUrl" placeholder="菜单路由地址"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :lg="12" :md="24" :sm="24">
                    <el-form-item label="备注" prop="description">
                      <el-input v-model="nodeForm.description" placeholder="备注信息"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row style="text-align:right">
                  <el-button size="small" @click="closeAdd">取消</el-button>
                  <el-button type="primary" size="small" @click="submitAdd">保存</el-button>
                </el-row>
              </el-form>
            </template>
            <div class="addBg" :class="{'open': nodeOpt}"></div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <import-file :type="importType" @import-success="importSuccess" :activeId="activeId" :title="importTitle" :dialogVisible="dialogVisible" @close="dialogVisible=false"></import-file>
  </div>
</template>
<script>
import * as validFn from '@/utils/validate'
import { deepClone, exportExcel} from '@/utils/index'
import { getSystemList, addSystem, editSystem, updateSystem, deleteSystem } from '@/api/companySystemList'
// import { allSystem } from '@/api/account'
// import { getUserInfoByName } from '@/api/login'
import { userMenu, addUserMenu, editUserMenu, deleteUserMenu } from '@/api/menu-manage'
import { exportSystemMenu } from '@/api/privilegeImport' 
import permission from '@/utils/permissionsData'
export default {
  data() {
    return {
      maxHeight: document.documentElement.clientHeight - 190,
      inputWidth: document.documentElement.clientWidth > 1600 ? '370px' : (document.documentElement.clientWidth > 1200 ? '290px' : '370px'),
      searchForm: {
        usName: ''
      },
      activeId: null,
      old_activeId: null,
      systemList: [],
      systemAll: [],
      systemForm: {
        usId: null,
        usName: '',
        usUrl: '',
        description: '',
        directorId: null,
        directorName: '',
        directorWorkcode: '',
        usDesc: ''
      },
      rules: {
        usName: [validFn.required()],
        usUrl: [validFn.required()],
        description: [validFn.required()],
        directorId: [validFn.requiredC()],
        usDesc: [validFn.required()]
      },
      userList: [],
      allUserList: [],
      systemType: 'read',
      treeData: [],
      defaultProps: {
        label: 'umTitle',
        children: 'childUserMenu'
      },
      nodeOpt: false,
      parentNode: '顶级菜单',
      nodeForm: {
        umParentId: -1, // 顶级菜单
        umTitle: '',
        umEnTitle: '',
        umUrl: '',
        umSort: null,
        description: '',
        umColor: '',
        umCss: '',
        usId: null,
        icon: '' // svg图标
      },
      nodeRules: {
        // umTitle: [validFn.required(), validFn.hasZh()],
        umTitle: [validFn.required()],
        umEnTitle: [validFn.required()],
        umSort: [validFn.required(), validFn.isNum()],
        umUrl: [validFn.required()]
      },
      openType: 'top', // top: 顶级菜单，normal: 普通菜单
      loading: false,
      isEdit: false,
      dialogVisible: false,
      importTitle: '系统菜单配置导入',
      importType: 'system',
      menuImport: permission.menuImport
    }
  },
  computed: {
    allUsers() {
      return this.$store.getters.allUserList
    }
  },
  watch: {
    allUsers(val, oldVal) {
      if (val && val.length > 0) {
        this.allUserList = val.map(i => {
          return {
            name: i.name,
            username: i.username,
            userId: i.userId
          }
        })
      }
    }
  },
  mounted() {
    // 重新计算高度
    const _this = this
    window.onresize = () => {
      return (() => {
        window.maxHeight = document.documentElement.clientHeight - 190
        _this.maxHeight = window.maxHeight < 150 ? 150 : window.maxHeight
        _this.inputWidth = document.documentElement.clientWidth > 1600 ? '370px' : (document.documentElement.clientWidth > 1200 ? '290px' : '370px')
      })()
    }
    this.getSystem()
    // this.getUser()
    if (this.allUsers && this.allUsers.length < 1) {
      this.$store.dispatch('GetAllUser')
    }
  },
  methods: {
    // 系统列表
    getSystem() {
      const params = {
        usName: this.searchForm.usName,
        pageSize: 1000,
        pageNum: 1
      }
      getSystemList(params).then(res => {
        if (res.code === '000000' && res.data) {
          this.systemList = res.data.list
          this.systemAll = res.data.list
          if (res.data.list.length) {
            this.userList = [{ name: res.data.list[0].directorName, username: res.data.list[0].directorWorkcode, userId: res.data.list[0].directorId }]
            Object.keys(this.systemForm).forEach(key => {
              this.systemForm[key] = res.data.list[0][key] ? res.data.list[0][key] : ''
            })
            // this.systemForm = res.data.list[0]
            // 先写死
            // this.systemForm.usManager = 172
            // this.systemForm.name = '超管zeng1'
            // if (this.systemForm.directorName) {
            //   this.filterUser(this.systemForm.directorName)
            // }
            this.activeId = res.data.list[0].usId
            this.getMenuTree()
          }
        }
      })
    },
    filterSystem(val) {
      console.log(val)
      this.systemList = this.systemAll.filter(item => {
        return item.usName.indexOf(val) > -1
      })
    },
    // 人员列表
    // getUser() {
    //   getUserInfoByName({ pageNum: 1, pageSize: 10000 }).then(res => {
    //     if (res.code === '000000') {
    //       this.allUsers = res.data.list
    //     }
    //   }).catch()
    // },
    filterUser(val) {
      if (val) {
        console.log(this.allUserList)
        this.userList = this.allUserList.filter(item => item.name.indexOf(val.trim()) > -1)
        // this.userList = this.allUsers.filter(item => {
        //   return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1
        // })
      }
    },
    // 获取菜单
    getMenuTree() {
      this.loading = true
      userMenu(this.activeId).then(res => {
        if (res.code === '000000' && res.data) {
          this.treeData = res.data
        }
        this.loading = false
      }).catch(() => {
        this.treeData = []
        this.loading = false
      })
    },
    addSystem() {
      this.$refs.systemForm.resetFields()
      this.systemType = 'add'
      this.userList = []
      this.treeData = []
      this.old_activeId = this.activeId
      this.activeId = null
    },
    // 获取系统详情
    getDetails() {
      editSystem(this.activeId).then(res => {
        if (res.code === '000000') {
          this.systemForm = deepClone(res.data)
          if (res.data.directorId) {
            this.userList = [{ name: res.data.directorName, username: res.data.directorWorkcode, userId: res.data.directorId }]
          } else {
            this.userList = []
          }
        } else {
          this.$message.error('获取系统详情失败！')
        }
      }).catch(() => {
        this.$message.error('获取系统详情失败！')
      })
    },
    // 取消 编辑，新增
    cancelOpt() {
      console.log(this.systemForm)
      if (this.systemType === 'add') {
        this.$refs.systemForm.resetFields()
        this.activeId = this.old_activeId
        if (this.activeId) {
          this.getDetails()
          this.getMenuTree()
        }
      } else if (this.systemType === 'edit') {
        const data = this.systemList.filter(item => item.usId === this.systemForm.usId)[0]
        Object.keys(this.systemForm).forEach(key => {
          this.systemForm[key] = data[key] ? data[key] : ''
        })
      }
      this.systemType = 'read'
    },
    // 删除系统
    deleteSystem() {
      this.$confirm(`确定删除 ${this.systemForm.usName} 系统？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteSystem(this.activeId).then(res => {
          if (res.code === '000000') {
            this.$message.success(res.message)
            this.systemType = 'read'
            this.getSystem()
          }
        }).catch()
      }).catch(() => {
        this.$message.info('已取消！')
      })
    },
    // 编辑系统保存
    saveEdit() {
      this.$refs.systemForm.validate(valid => {
        if (valid) {
          const person = this.userList.filter(item => { return item.userId === this.systemForm.directorId })[0]
          this.systemForm.directorName = person.name
          this.systemForm.directorWorkcode = person.username
          console.log(this.systemForm)
          updateSystem(this.systemForm).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.message)
              this.systemType = 'read'
            }
          }).catch()
        } else {
          return false
        }
      })
    },
    // 新增系统保存
    saveAdd() {
      this.$refs.systemForm.validate(valid => {
        if (valid) {
          const person = this.userList.filter(item => { return item.userId === this.systemForm.directorId })[0]
          this.systemForm.directorName = person.name
          this.systemForm.directorWorkcode = person.username
          const params = deepClone(this.systemForm)
          delete params.usId
          addSystem(params).then(res => {
            if (res.code === '000000') {
              this.$message.success(res.message)
              this.systemType = 'read'
              this.getSystem()
            }
          }).catch()
        } else {
          return false
        }
      })
    },
    // 绘制节点操作按钮, 最多有3级菜单
    treeRenderContent(h, { node, data, store }) {
      if (node.level < 4) {
        return (
          <span class='custom-tree-node'>
            <span>{node.label}</span>
            <span>
              <el-button
                size='mini'
                type='text'
                on-click={() => this.appendTree(data)}>
                增加
              </el-button>
              <el-button
                size='mini'
                type='text'
                on-click={() => this.editTree(data)}>
                编辑
              </el-button>
              <el-button
                size='mini'
                type='text'
                on-click={() => this.removeTree(node, data)}>
                删除
              </el-button>
            </span>
          </span>
        )
      } else {
        return (
          <span class='custom-tree-node'>
            <span>{node.label}</span>
            <span>
              <el-button
                size='mini'
                type='text'
                on-click={() => this.editTree(data)}>
                编辑
              </el-button>
              <el-button
                size='mini'
                type='text'
                on-click={() => this.removeTree(node, data)}>
                删除
              </el-button>
            </span>
          </span>
        )
      }
    },
    // 添加顶级菜单，若当前新增form是open的，需判断是否是当前要新增的类型(是否是顶级菜单)，处理是否重置form
    addTopNode() {
      this.isEdit = false
      if (this.nodeOpt) {
        if (this.openType === 'normal') {
          this.$refs.nodeForm.resetFields()
        }
      } else {
        this.nodeOpt = true
      }
      this.nodeForm.umParentId = -1
      this.parentNode = '顶级菜单'
    },
    // 添加子菜单
    appendTree(node) {
      console.log(node)
      this.isEdit = false
      this.openType = 'normal'
      if (this.nodeOpt) {
        this.$refs.nodeForm.resetFields()
      } else {
        this.nodeOpt = true
      }
      this.parentNode = node.umTitle
      this.nodeForm.umParentId = node.umId
    },
    // 编辑操作
    editTree(node) {
      console.log(node)
      this.isEdit = true
      if (node.umParentId === -1) {
        this.parentNode = '顶级菜单'
        this.openType = 'top'
      } else {
        this.parentNode = this.findParent(node.umParentId, this.treeData)
        this.openType = 'normal'
      }
      this.nodeOpt = true
      Object.keys(this.nodeForm).forEach(keys => {
        this.nodeForm[keys] = node[keys]
      })
      this.nodeForm.umId = node.umId
    },
    // 批量删除有点鸡肋，暂时不考虑
    removeTree(node, data) {
      console.log(node)
      console.log(data)
      if (data.childUserMenu.length) {
        this.$message.warning('该菜单含有子菜单，不可直接删除')
        return
      }
      this.$confirm('确定删除该节点数据？', '提示', {
        type: 'warning',
        cancelButtonText: '取消',
        confirmButtonText: '确定'
      }).then(() => {
        deleteUserMenu([data.umId]).then(res => {
          if (res.code === '000000') {
            this.$message.success('操作成功！')
            this.getMenuTree()
          }
        })
      }).catch(() => {
        this.$message.info('已取消！')
      })
    },
    // 关闭新增菜单
    closeAdd() {
      this.parentNode = '顶级菜单'
      this.openType = 'top'
      this.$refs.nodeForm.resetFields()
      this.nodeOpt = false
    },
    // 编辑时查找父菜单，仅展示用
    findParent(id, arr) {
      let str = ''
      arr.forEach(item => {
        if (id === item.umId) {
          str = item.umTitle
        } else {
          if (item.childUserMenu.length) {
            this.findParent(id, item.childUserMenu)
          }
        }
      })
      return str
    },
    // 新增 编辑菜单 提交
    submitAdd() {
      this.$refs.nodeForm.validate(valid => {
        if (valid) {
          console.log(this.nodeForm)
          const params = deepClone(this.nodeForm)
          params.umSort = Number(params.umSort)
          params.usId = this.activeId
          delete params.icon // 暂时删除图标字段，
          if (!this.isEdit) {
            if (params.umId) {
              delete params.umId
            }
            addUserMenu(params).then(res => {
              if (res.code === '000000') {
                this.getMenuTree()
                this.closeAdd()
                this.$message.success('操作成功！')
              }
            }).catch(err => {
              console.log(err)
            })
          } else {
            editUserMenu(params).then(res => {
              if (res.code === '000000') {
                this.getMenuTree()
                this.closeAdd()
                this.$message.success('操作成功！')
              }
            }).catch(err => {
              console.log(err)
            })
          }
        } else {
          return false
        }
      })
    },
    // 切换系统
    changeSystem(data) {
      if (data.usId === this.activeId) return
      if (this.systemType === 'add') {
        this.activeId = data.usId
        this.$refs.systemForm.resetFields()
        this.systemType = 'read'
        this.getDetails()
      } else if (this.systemType === 'read') {
        this.activeId = data.usId
        this.$refs.systemForm.resetFields()
        this.getDetails()
      }
      if (this.systemType === 'edit') {
        this.$confirm('是否保存当前修改？', '提示', {
          type: 'warning',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          // 修改系统信息
          this.saveEdit()
        }).catch(() => {
          this.activeId = data.usId
          this.$refs.systemForm.resetFields()
          this.systemType = 'read'
          this.getDetails()
          this.getMenuTree()
        })
      }
      // 显示对应的树形菜单
      this.closeAdd()
      this.getMenuTree()
    },
    // 最外层导出
    async exportAllMenu(type){
      try {
        const params = type === 'sys' ? {usIdList: this.activeId} : {}
        const res = await exportSystemMenu(params)
        exportExcel(res.data, '系统菜单')
      } catch (error) {
        console.error(error);
      }
    },
    importMenu(type) {
      this.importType = type
      this.importTitle = type === 'system' ? '系统菜单配置导入' : '菜单配置导入'
      this.dialogVisible = true
    },
    importSuccess(){
      this.dialogVisible = false
      if(this.importType === 'system'){
        this.getSystem()
      }else{
        this.getMenuTree()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes open {
  0% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
@-webkit-keyframes open {
  0% {
    height: 100%
  }
  100% {
    height: 0;
  }
}
.systemPan {
  width: 94%;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  background: #eceff0;
  color: #766c6c;
  font-size: 14px;
  margin: 0 auto 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-user-select: none;
  &:last-child {
    margin-bottom: 0
  }
  &:hover {
    color: #409EFF;
    background: rgba(64,158,255,.1);
  }
  &.active {
    color: #409EFF;
    background: rgba(64,158,255,.1);
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-aside {
  position: relative;
  height: 100%;
  /*max-height: 600px;*/
  overflow-x: hidden;
  /*border-left: 2px solid #eee;*/
  padding-right: 12px;
  //滚动条凹槽的颜色，还可以设置边框属性
  &::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  //滚动条的宽度
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  //滚动条的设置
  &::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 28px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }
}
.addBg {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffff;
  opacity: 1;
  &.open {
    animation: open 0.5s ease-in forwards;
    -webkit-animation: open 0.5s ease-in forwards;
  }
}
.tip_txt {
  line-height: 22px;
  color: rgba(0,0,0,0.45);
  font-size: 13px;
  padding-top:10px;
}
</style>

